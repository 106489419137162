import React from "react";
import ReactDOM from "react-dom";
import './css/Footer.scss';
import profileImg from './img/about-img.png';
import globe from './img/ikoner/globe.svg';
import design from './img/ikoner/design.svg';
import work from './img/ikoner/work.svg';

export function Footer() {
    return (

      <div className="footer">
        <div className="wrapper">
          <div className="content">
            <div className="text">
              <h4>Kontakt</h4>
              <h3>Vil du høre mer?</h3>
              <p>Ta gjerne kontakt om du vil høre mer om meg, mine referanser, kompetanseprofil eller noe helt annet.</p>
              <a href="https://www.linkedin.com/in/christian-kjelland-bilstad/" target="_blank" className="l1">Ta kontakt</a>
          </div>
          <div className="card">
            <div className="c-header">
              <img src={profileImg} alt="Christian" />
              <p>Christian Kjelland Bilstad</p>
            </div>
            <div className="c-footer">
              <div className="highlights">
                <div className="item">
                  <img src={globe} />
                  <span className="mobile">TRD</span>
                  <span className="desktop">Trondheim</span>
                </div>
                <div className="item">
                  <img src={design} />
                  <span className="mobile">UI/UX Designer</span>
                  <span className="desktop">UI/UX Designer</span>
                </div>
                <div className="item">
                  <img src={work} />
                  <span>Kantega</span>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="links-wrapper">
            <hr />
            <div className="links">
              <div className="left">
                <h4>©  2024</h4>
              </div>
              <div className="right">
                <h4><a href="personvern.html" className="l2">Personvern</a></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const app = document.getElementById("footer");
ReactDOM.render(<Footer />, footer);